@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.exams_container {
    background-image: linear-gradient(to bottom, #ebebeb, #ffffff);
}

.exams_text_container {
    padding-top: 20px;
    text-align: center;
}

.exams_title {
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;

    line-height: 0.5;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 15px;
}

.exams_description {
    margin-top: 10px;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #666666;
}

.exams_user_container {
    display: flex;
    margin-top: 40px;
    text-align: center;
    flex-direction: row;
    align-items: center;
    place-content: center;
}

.exams_info_text {
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
    margin-right: 20px;
}

.exams_location_text {
    margin-left: 6px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;

    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
    margin-right: 20px;
}

.exams_location_change {
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;

    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #EB6619;
    cursor: pointer;
}

.exams_location_bar {
    margin: auto;
    width: 30em;
    position: relative;
    height: 1px;
    background-color: #666666;
    top: -12px;
}

.exams_service_container {
    text-align: center;
}

.exams_service_text {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;

    line-height: 1.86;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
    margin-bottom: 5px;
}

.exams_service_select {
    padding: 1em;
    width: 564px;
    height: 56px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #cacaca;
    background-color: #ffffff;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;

    line-height: 1.86;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
}

.select-selected:after {
    width: 24px;
    height: 14px;
    background-color: #666666;
}

.location_modal_close_icon {
    top: 12px;
    right: 12px;
    cursor: pointer;
    position: absolute;
}

.location_modal_container {
    display: flex;
    flex-direction: column;
}

.location_modal_title {
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 0.5;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 0px;
}

.location_modal_description {
    margin-top: 20px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.location_modal_select_label {
    display: block;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
}

.location_modal_select {
    display: block;
    width: 375px;
    height: 56px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #cacaca;
    background-color: #ffffff;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;

    line-height: 1.86;
    letter-spacing: normal;
    text-align: left;
    color: #b8b8b8;
    margin-bottom: 30px;
}

.location_modal_buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
}

.location_modal_button_ghost {
    width: 120px;
    height: 60px;
    margin-right: 17px;
    border-radius: 4px;
    color: #EB6619;
    border: solid 2px #EB6619;
    background-color: #ffffff;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
}

.location_modal_button {
    width: 220px;
    height: 60px;
    border: none;
    border-radius: 4px;
    background-color: #EB6619;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.exams_services_results {
    margin-top: 80px;
    text-align: center;
    margin-bottom: 80px;
}

.exams_result_list_container {

}

.exams_result_list_container .exams_result_count_container {
    width: auto;
    height: 32px;
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-bottom: 40px;
}

.exams_result_list_item {
    height: 100px;
    display: flex;
    width: 62.6em;
    flex-direction: row;
    margin: auto auto 16px;
    border: solid 2px #dcdcdc;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.exams_result_list_item_logo_container {
    display: flex;
    flex-direction: row;
}

.exams_result_item_logo {
    height: 75px;
    margin-top: auto;
    margin-left: 16px;
    margin-bottom: auto;
}

.exams_result_item_type {
    width: 110px;
    color: #eb6619;
    margin-left: 15px;
    align-self: center;
    height: fit-content;
    border-radius: 100px;
    border: solid 1px #eb6619;
    background-color: #ffffff;
}

.exams_result_item_type_label {
    margin: 5px;
    width: auto;
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
}

.exams_result_item_description_container {
    min-width: 480px;
    margin-left: 4em;
    margin-right: 2em;
    align-self: center;
}

.exams_result_item_description_text {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.exams_result_item_button_container {
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: row;
}

.exams_result_item_button {
    border: none;
    cursor: pointer;
    margin-left: 2em;
    margin-right: 2em;
    border-radius: 4px;
    height: fit-content;
    background-color: #eb6619;
}

.exams_result_item_button_text {
    margin: 8px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.exams_result_item_select {
    height: 40px;
    width: 240px;
    border: solid 1px #cacaca;
    background-color: #ffffff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.exams_result_item_select_option {
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.exams_result_item_select_label {
    display: flex;
    flex-direction: column;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
    margin-bottom: 1em;
}

.loading_info_text {
    padding-top: 40px;
    font-family: 'Lato', sans-serif;
    text-align: center;
    color: #f5915f;
}

.exams_location_container {
    display: flex;
    align-items: center;
    flex-direction: row;
}

@media only screen and (max-width: 770px) {
    .exams_description {
        display: none;
    }

    .exams_text_container {
        padding-top: 1px;
    }

    .exams_info_text {
        margin-right: 0;
        margin-bottom: 0;
    }

    .exams_user_container {
        flex-direction: column;
    }

    .exams_location_change {
        margin-top: 0;
        margin-bottom: 2em;
    }

    .exams_location_bar {
        width: 20em;
    }

    .exams_service_select {
        width: 24em;
    }

    .exams_services_results {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .location_modal_select {
        width: 300px;
    }

    .location_modal_button {
        width: 160px;
        font-size: 20px;
    }

    .exams_result_list_item {
        width: 350px;
        min-height: 260px;
        height: fit-content;
        flex-direction: column;
    }

    .exams_result_list_item_logo_container {
        margin-top: 10px;
        align-self: center;
        margin-bottom: 10px;
    }

    .exams_result_item_description_container {
        width: 255px;
        height: 100px;
        min-width: unset;
        margin-left: unset;
        margin-right: unset;
        margin-bottom: 20px;
    }

    .exams_result_item_description_text {
        text-align: center;
    }

    .exams_result_item_button_container {
        flex-direction: column;
    }

    .exams_result_item_button {
        margin-bottom: 20px
    }
}
