@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
    margin: unset;
}

/* width */
::-webkit-scrollbar {
    width: .4em;
    height: .4em;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(210, 210, 210);
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #EB6619;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #e97423;
}
