.auth-error {
    height: 100%;
    text-align: center;
}

.auth-error-logo {
    height: 80px;
    margin: 50px auto auto;
}

.auth-error-content {
    height: 100%;
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    justify-content: center;
}

.auth-error-message {
    width: 350px;
    height: 100px;
    color: #e97423;
    font-size: 28px;
    text-align: left;
    margin: 30px 0 0 30px;
    font-family: 'Lato', sans-serif;
}

.auth-error-icon {
    height: 100px;
}

.auth-error-footer {
    bottom: 0;
    width: 100%;
    position: absolute;
}

@media only screen and (max-width: 980px) {
    .auth-error-content {
        flex-direction: column;
    }

    .auth-error-message {
        text-align: center;
        margin: 40px auto auto;
    }
}
