@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);

.header_container {
    height: 160px;
    display: flex;
    flex-direction: row;
    place-content: center;
}

.header_logo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.slash_division {
    margin: auto 2em;
    width: 2px;
    height: 5em;
    opacity: 0.50;
    transform: rotate(10deg);
    background-color: #707070;
}

.natura_logo {
    left: 6em;
    height: 5em;
    position: absolute;
}

.header_menu {
    display: flex;
    flex-direction: row;
    margin-left: 4em;
    text-transform: uppercase;
    font-family: Lato, 'sans-serif';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    align-self: center;
}

.drop_down_item {
    display: flex;
    flex-direction: column;
}

.menu_item {
    text-align: center;
    margin-right: 1.5em;
    margin-left: 1.5em;
    text-decoration: none;
    color: #000000;
}

.desktop_header {
    display: block;
}

.mobile_header {
    display: none;
    margin-top: 1em;
    margin-bottom: 1em;
}

.natura_logo_mobile {
    height: 50px;
    margin-left: 20px;
    margin-right: 24px;
}

.topnav {
    display: flex;
    overflow: hidden;
    background-color: #ffffff;
    width: 24em;
}

.mobile_header_menu {
    color: #000000;
    height: 50px;
    margin-right: 20px;
}

.mobile_header_logos {
    position: relative;
    margin: auto auto auto 0;
}

.topnav .topnav_itens {
    position: absolute;
    width: 102%;
    margin-top: 54.3px;
     margin-left: -4px;
    background-color: #ffff;
    transition: all .5s ease-in-out;
}

.menu_item_dropdown_icon {
    cursor: pointer;
    margin-left: -1em;
    margin-right: 1.5em;
}

.sub_menu_dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #ffffff;
    border: solid 1px #707070;
    padding-left: 20px;
    padding-right: 80px;
    padding-top: 20px;
    top: 7em;
}

.sub_menu_item {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    text-align: left;
    height: 40px;
    color: #000000;
}

/* Style navigation menu links */
.topnav a {
    font-family: Lato, 'sans-serif';
    color: black;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
    text-align: center;
    margin-right: unset;
}

/* Style the hamburger menu */
.topnav a.icon {
    background: black;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
}

/* Add a grey background color on mouse-over */
.topnav a:hover {
    background-color: #ddd;
    color: black;
}

/* Style the active link (or home/logo) */
.active {
    background-color: #4CAF50;
    color: white;
}

@media only screen and (max-width: 770px) {
    .header_logo {
        display: none;
    }

    .header_menu {
        display: none;
    }

    .desktop_header {
        display: none;
    }

    .mobile_header {
        display: flex;
        flex-direction: row;
    }

    .header_container {
        height: auto;
    }

    .topnav a {
        margin: auto;
    }

    .menu_item_dropdown_icon {
        position: absolute;
        margin-left: 1em;
    }

    .mobile_sub_menu {
        border: solid 1px #707070;
        background-color: #ececec;
    }

    .mobile_header_logos:hover {
        background-color: unset !important;
    }
}

.welcome_container {
    height: 500px;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
}

.welcome_text_container {
    display: flex;
    flex-direction: column;
    float: right;
    margin-top: 5em;
    margin-right: 10em;
}

.welcome_title {
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;

    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    float: right;
}

.welcome_description {
    width: 276px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    float: right;
    position: relative;
    right: -8em;
}

@media only screen and (max-width: 770px) {
    .welcome_container {
        height: 250px;
        background-position-x: -32em;
    }

    .welcome_text_container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        float: right;
        margin-top: 0;
        margin-right: 0;
        background-color: rgba(172, 143, 126, 0.9);
    }

    .welcome_title {
        font-family: 'Roboto', sans-serif;
        margin-right: auto;
        margin-left: 3em;
        width: 10em;
        text-align: left;
        font-size: 20px;
        line-height: 1.2;
    }

    .welcome_description {
        font-size: 18px;
        width: 14em;
        text-align: left;
        margin-right: auto;
        margin-left: auto;
        margin-top: -15px;
        position: unset;
    }
}
.greeting_container {
    height: 200px;
    display: flex;
    margin-top: 60px;
    margin-bottom: 40px;
    flex-direction: row;
    place-content: center;
}

.greeting_heart {
    height: 10em;
    margin-right: 50px;
    margin-top: auto;
    margin-bottom: auto;
}

.greeting_title {
    font-family: Lato, sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-top: .5em;
}

.greeting_nickname {
    text-transform: capitalize;
}

.greeting_description {
    max-width: 850px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

@media only screen and (max-width: 770px) {
    .greeting_container {
        height: 300px;
        margin-top: auto;
    }

    .greeting_icon {
        display: none;
    }

    .greeting_title {
        width: 15em;
        font-family: 'Roboto', sans-serif;;
        font-size: 20px;
        text-align: left;
        line-height: 24px;
        margin: 30px auto -10px;
        font-weight: 800;
        padding: .5em;
        color: #000000;
    }

    .greeting_description {
        width: 17em;
        margin: 20px auto auto;
    }
}
.needing_container {
    height: 1400px;
    background-image: linear-gradient(to bottom, #ebebeb, #ffffff);
}

.needing_text_container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.needing_title {
    margin-top: 50px;
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;

    line-height: 0.5;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 15px;
}

.needing_description {
    margin-top: 15px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
}

.services_container {
    padding-top: 30px;
    margin: auto auto 130px;
}

.service_card {
    width: 440px;
    margin-left: 1em;
    margin-right: 1em;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.service_card:hover {
    /*border: solid 2px #EB6619;*/
}

.service_image {
    width: 440px;
    height: 300px;
}

.card_text_container {
    padding: 2em;
}

.card_title {
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 0.5;
    letter-spacing: normal;
    text-align: left;
    color: #EB6619;
}

.card_description {
    height: 50px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
    margin-top: 10px;
    margin-bottom: 5px;
}

.card_button {
    cursor: pointer;
    top: 1em;
    left: 4em;
    position: relative;
    width: 250px;
    height: 40px;
    border-radius: 4px;
    background-color: #EB6619;
    font-family: Lato, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding-top: .8em;
}

.card_button:hover {
    background-color: #e97423;
}

.partners_text_container {
    text-align: center;
}

.partner_title {
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 0.5;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 20px;
}

.partner_description {
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #051a31;
}

.partner_images_container {
    place-content: center;
}

.partner_image {
    height: 140px;
    margin: 4em;
}

.partner_name {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;

    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #EB6619;
}

.images_content {
    /*cursor: pointer;*/
}

.mobile_only {
    display: none;
}

div .services_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.services_container .flickity-viewport {
    height: 620px !important;
}

.partner_images_container .flickity-prev-next-button.next {
    right: 4px !important;
}

.partner_images_container .flickity-prev-next-button.previous {
    left: 0 !important;
}

.partner_images_container .flickity-prev-next-button {
    width: 55px !important;
    height: 170px !important;
    border-radius: unset !important;
}

.partner_images_container .flickity-button {
    background: hsla(0,0%,98%,1) !important;
    color: #000000 !important;
}

.partner_images_container .flickity-button:hover {
    background: hsla(0,0%,98%,1) !important;
}

.partner_images_container .flickity-button:active {
    opacity: 1 !important;
    background: hsla(0,0%,98%,1) !important;
}

@media only screen and (max-width: 770px) {

.needing_container {
    height: 1000px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.needing_title {
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    font-style: unset;
    font-family: 'Roboto', sans-serif;
}

.needing_description {
    margin-top: 5px;
}

.mobile_only {
    display: block;
}

div .services_container {
    display: block;
    justify-content: unset;
    padding: 0 .5em;
}

.card_image {
    overflow: hidden;
}

.service_card {
    height: 422px;
    width: 300px;
    margin-right: 1em;
    margin-left: 1em;
}

.service_image {
    height: 206px;
    width: 320px;
}

.services_container .flickity-viewport {
    height: 460px !important;
}

.services_container .flickity-prev-next-button.next {
        right: 0 !important;
}

.services_container .flickity-prev-next-button {
    border-radius: unset !important;
}

.services_container .flickity-prev-next-button.previous {
        left: 0 !important;
}

.services_container .flickity-button {
    background: hsla(0,0%,98%,0) !important;
    color: #000000 !important;
}

.card_text_container {
    padding: unset;
}

.card_title {
    font-size: 20px;
    margin: 24px auto 20px 1.5em;
    font-style: unset;
    font-family: 'Roboto', sans-serif;
}

.card_description {
    height: 90px;
    color: #333333;
    font-size: 18px;
    margin-left: 1.5em;
    margin-right: 1.5em;
    margin-bottom: 20px;
    line-height: 22px;
}

.card_button {
    top: 1.2em;
    left: 3.4em;
    width: 170px;
    font-size: 20px;
    padding: 13px 0 0;
}

.partner_title {
    padding-top: 1.5em;
    font-family: 'Roboto', sans-serif;
    font-style: unset;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
}

.partner_description {
    width: 16em;
    margin: auto;
}

.partner_name {
    text-align: center;
    line-height: 20px;
    font-size: 18px;
    font-style: unset;
    margin-top: -50px;
}

.partner_image {
    margin: 2.5em 1em 10px;
}

.partner_image img {
    height: 112px;
}

.partners_container {
    margin: -5em;
}

.partner_images_container {
    width: 71%;
    margin-left: 80px;
}
}

.footer_content {
    text-align: center;
    margin-bottom: 35px;
}

.footer_text {
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #3f1c57;
}

.footer_division {
    margin-bottom: 35px;
}

.footer_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer_logo {
    position: absolute;
    display: block;
    left: 10em;
}

.footer_logo_mobile {
    display: none;
}

.powered_by {
    left: 14.24em;
    color: #000000;
    font-size: 16px;
    text-align: left;
    position: absolute;
    margin-top: -1.4em;
    font-family: 'Lato', sans-serif;
}

@media only screen and (max-width: 770px) {
    .powered_by {
        display: none;
    }

    .footer_text {
        font-size: 14px;
        margin-left: 10px;
    }

    .footer_logo {
        display: none;
    }

    .footer_logo_mobile {
        display: block;
    }
}
.exams_container {
    background-image: linear-gradient(to bottom, #ebebeb, #ffffff);
}

.exams_text_container {
    padding-top: 20px;
    text-align: center;
}

.exams_title {
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;

    line-height: 0.5;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 15px;
}

.exams_description {
    margin-top: 10px;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #666666;
}

.exams_user_container {
    display: flex;
    margin-top: 40px;
    text-align: center;
    flex-direction: row;
    align-items: center;
    place-content: center;
}

.exams_info_text {
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
    margin-right: 20px;
}

.exams_location_text {
    margin-left: 6px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;

    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
    margin-right: 20px;
}

.exams_location_change {
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;

    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #EB6619;
    cursor: pointer;
}

.exams_location_bar {
    margin: auto;
    width: 30em;
    position: relative;
    height: 1px;
    background-color: #666666;
    top: -12px;
}

.exams_service_container {
    text-align: center;
}

.exams_service_text {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;

    line-height: 1.86;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
    margin-bottom: 5px;
}

.exams_service_select {
    padding: 1em;
    width: 564px;
    height: 56px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #cacaca;
    background-color: #ffffff;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;

    line-height: 1.86;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
}

.select-selected:after {
    width: 24px;
    height: 14px;
    background-color: #666666;
}

.location_modal_close_icon {
    top: 12px;
    right: 12px;
    cursor: pointer;
    position: absolute;
}

.location_modal_container {
    display: flex;
    flex-direction: column;
}

.location_modal_title {
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 0.5;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 0px;
}

.location_modal_description {
    margin-top: 20px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.location_modal_select_label {
    display: block;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
}

.location_modal_select {
    display: block;
    width: 375px;
    height: 56px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #cacaca;
    background-color: #ffffff;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;

    line-height: 1.86;
    letter-spacing: normal;
    text-align: left;
    color: #b8b8b8;
    margin-bottom: 30px;
}

.location_modal_buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
}

.location_modal_button_ghost {
    width: 120px;
    height: 60px;
    margin-right: 17px;
    border-radius: 4px;
    color: #EB6619;
    border: solid 2px #EB6619;
    background-color: #ffffff;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
}

.location_modal_button {
    width: 220px;
    height: 60px;
    border: none;
    border-radius: 4px;
    background-color: #EB6619;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.exams_services_results {
    margin-top: 80px;
    text-align: center;
    margin-bottom: 80px;
}

.exams_result_list_container {

}

.exams_result_list_container .exams_result_count_container {
    width: auto;
    height: 32px;
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-bottom: 40px;
}

.exams_result_list_item {
    height: 100px;
    display: flex;
    width: 62.6em;
    flex-direction: row;
    margin: auto auto 16px;
    border: solid 2px #dcdcdc;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.exams_result_list_item_logo_container {
    display: flex;
    flex-direction: row;
}

.exams_result_item_logo {
    height: 75px;
    margin-top: auto;
    margin-left: 16px;
    margin-bottom: auto;
}

.exams_result_item_type {
    width: 110px;
    color: #eb6619;
    margin-left: 15px;
    align-self: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 100px;
    border: solid 1px #eb6619;
    background-color: #ffffff;
}

.exams_result_item_type_label {
    margin: 5px;
    width: auto;
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
}

.exams_result_item_description_container {
    min-width: 480px;
    margin-left: 4em;
    margin-right: 2em;
    align-self: center;
}

.exams_result_item_description_text {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.exams_result_item_button_container {
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: row;
}

.exams_result_item_button {
    border: none;
    cursor: pointer;
    margin-left: 2em;
    margin-right: 2em;
    border-radius: 4px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #eb6619;
}

.exams_result_item_button_text {
    margin: 8px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.exams_result_item_select {
    height: 40px;
    width: 240px;
    border: solid 1px #cacaca;
    background-color: #ffffff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.exams_result_item_select_option {
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.exams_result_item_select_label {
    display: flex;
    flex-direction: column;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
    margin-bottom: 1em;
}

.loading_info_text {
    padding-top: 40px;
    font-family: 'Lato', sans-serif;
    text-align: center;
    color: #f5915f;
}

.exams_location_container {
    display: flex;
    align-items: center;
    flex-direction: row;
}

@media only screen and (max-width: 770px) {
    .exams_description {
        display: none;
    }

    .exams_text_container {
        padding-top: 1px;
    }

    .exams_info_text {
        margin-right: 0;
        margin-bottom: 0;
    }

    .exams_user_container {
        flex-direction: column;
    }

    .exams_location_change {
        margin-top: 0;
        margin-bottom: 2em;
    }

    .exams_location_bar {
        width: 20em;
    }

    .exams_service_select {
        width: 24em;
    }

    .exams_services_results {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .location_modal_select {
        width: 300px;
    }

    .location_modal_button {
        width: 160px;
        font-size: 20px;
    }

    .exams_result_list_item {
        width: 350px;
        min-height: 260px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        flex-direction: column;
    }

    .exams_result_list_item_logo_container {
        margin-top: 10px;
        align-self: center;
        margin-bottom: 10px;
    }

    .exams_result_item_description_container {
        width: 255px;
        height: 100px;
        min-width: unset;
        margin-left: unset;
        margin-right: unset;
        margin-bottom: 20px;
    }

    .exams_result_item_description_text {
        text-align: center;
    }

    .exams_result_item_button_container {
        flex-direction: column;
    }

    .exams_result_item_button {
        margin-bottom: 20px
    }
}

body {
    margin: unset;
}

/* width */
::-webkit-scrollbar {
    width: .4em;
    height: .4em;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(210, 210, 210);
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #EB6619;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #e97423;
}

.auth-error {
    height: 100%;
    text-align: center;
}

.auth-error-logo {
    height: 80px;
    margin: 50px auto auto;
}

.auth-error-content {
    height: 100%;
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    justify-content: center;
}

.auth-error-message {
    width: 350px;
    height: 100px;
    color: #e97423;
    font-size: 28px;
    text-align: left;
    margin: 30px 0 0 30px;
    font-family: 'Lato', sans-serif;
}

.auth-error-icon {
    height: 100px;
}

.auth-error-footer {
    bottom: 0;
    width: 100%;
    position: absolute;
}

@media only screen and (max-width: 980px) {
    .auth-error-content {
        flex-direction: column;
    }

    .auth-error-message {
        text-align: center;
        margin: 40px auto auto;
    }
}

